export const colors = {
  black: '#000000',
  cloud: '#E1E1E1',
  white: '#FFFFFF',
  gold: '#B79C5F',
  goldDark: '#CDB47C',
  olive: {
    regular: '#5C604D',
    light1: '#DFE9E1',
    light2: '#D6E3D9',
    light3: '#C5D3C8',
    light4: '#E9EFD4',
  },
  ocean: {
    light1: '#DCE6F1',
    light2: '#D3E3F3',
    light3: '#C0D5EB',
  },
  lavender: {
    light1: '#EEEDFA',
    light2: '#DDDBF5',
    light3: '#D6D1F2',
    product: '#B98ED7',
  },
  fire: {
    light1: '#F6E3DC',
    light2: '#FFD1BF',
    light3: '#FFB396',
  },
  sun: {
    light1: '#FFFAEB',
    light2: '#FEF5D6',
    light3: '#FDEBAE',
  },
  backgroundGrey: '#f8f8f8',
  cardGrey: '#e9e9e9',
  UIGray1: '#999999',
  UIGray2: '#666666',
  UIGray3: '#333333',
  red: '#CC0909',
  yellow: '#FFDE31',
  turquoise: '#09C1CC',

  sunLight1: '#FFFAEB',
  sunLight2: '#FEF5D6',
  sunLight3: '#FDEBAE',
  fairPink: '#FFEDE7',
  // Old Colors
  darkCloud: '#777777',
  darkGrey: '#333333',
  lightBlue: '#F8FAFC',
  grey: '#6F7482',
  lightGrey: '#f5f5f5',
  // Functional colors
  text: 'black',
  background: 'white',
  danger: '#B42424',
  error: '#EE1A26',
  UIGray4: '#878787',
  UIGray5: '#D9D9D9',
  primary: 'black',
  secondary: 'white',
  accent: 'black',
  muted: '#F0FFF4',
  errorDark: '#B30909',
  translucentOlive: '#5C604D20',
};
